// Core
import { FormattedHTMLMessage } from 'react-intl';

// UI
import AuthorMain from './AuthorMain/AuthorMain';

// Icons
import plus_in_square from 'images/plus_in_square.svg';

// Styles
import s from './AuthorsShares.module.css';

const AuthorForm = ({
	errors,
	handleOnChange,
	handleOnFocus,
	handleOnBlur,
	tmpInputValue,
	selectPerson,
	setSelectPerson,
	checkControlledBySubmitter,
	setModal,
	onSave,
	isLoading,
	handleOpenModal,
}) => {
	const { contributors } = selectPerson;

	const addAuthor = () => {
		const newContributors = [
			...contributors,
			{
				title: '',
				society_id: null,
				roles: [],
				ownership: 0.1,
				publisher: '',
				publisher_share: 0,
				controlled_by_submitter: true,
				contributor_author_id: '',
			},
		];

		const controlledBySubmitterAuthors = contributors.filter(
			(item) => item.controlled_by_submitter === true
		);
		checkControlledBySubmitter(controlledBySubmitterAuthors);
		setSelectPerson({ ...selectPerson, contributors: newContributors });
	};

	const deleteAuthor = (id) => {
		const newContributors = contributors.filter(
			(contributor, index) => index !== id
		);
		setSelectPerson({ ...selectPerson, contributors: newContributors });
	};

	const addPublisher = (index) => {
		contributors[index].publisher_show = true;
		const newNumber = (contributors[index].ownership / 2).toFixed(2);

		contributors[index].publisher_share = newNumber;
		contributors[index].ownership = newNumber;
		setSelectPerson({ ...selectPerson });
	};

	const deletePublisher = (index) => {
		contributors[index].publisher_show = false;
		var newNumber = (
			Number(contributors[index].publisher_share) +
			Number(contributors[index].ownership)
		).toFixed(2);
		contributors[index].publisher = '';
		contributors[index].publisher_share = 0;

		newNumber = newNumber > 100 ? 100 : newNumber;
		contributors[index].ownership = newNumber;
		setSelectPerson({
			...selectPerson,
		});
	};

	const handleChangeArtist = (data, index, field) => {
		selectPerson.contributors[index].contributor_author_id = data;
		selectPerson.contributors[index].title = data.name;
		setSelectPerson({ ...selectPerson });
	};

	return (
		<div className={s.form}>
			<div className={s.authorsAndShares}>
				{selectPerson?.contributors &&
					selectPerson?.contributors.map((_, index) => (
						<AuthorMain
							key={index}
							index={index}
							handleOnChange={handleOnChange}
							errors={errors}
							contributors={contributors}
							handleOnFocus={handleOnFocus}
							handleOnBlur={handleOnBlur}
							tmpInputValue={tmpInputValue}
							addPublisher={addPublisher}
							selectPerson={selectPerson}
							onSave={onSave}
							setModal={setModal}
							isLoading={isLoading}
							deleteAuthor={deleteAuthor}
							deletePublisher={deletePublisher}
							handleChangeArtist={handleChangeArtist}
							handleOpenModal={handleOpenModal}
						/>
					))}
			</div>
			<div className={s.form__buttons}>
				<div className={s.form__buttonWrapper} onClick={addAuthor}>
					<img src={plus_in_square} alt="" />
					<FormattedHTMLMessage
						id={'rod.release.create.step.recording_info.label.author_add'}
					/>
				</div>
			</div>
		</div>
	);
};

export default AuthorForm;
