export const getFeaturedPerformers = (data) => {
	if (data && typeof data === 'string') {
		return [{ name: data }, ''];
	} else if (Array.isArray(data)) {
		const formattedPerformers = data.map((item) => {
			if (typeof item === 'string') {
				return { name: item };
			}
			return item;
		});
		return [...formattedPerformers, ''];
	} else {
		return [''];
	}
};
