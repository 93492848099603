export const formatPerformers = (performers) => {
	if (performers && typeof performers === 'string') {
		return [{ name: performers }, ''];
	} else {
		const resultArr = performers.map((item) => {
			if (typeof item === 'string') {
				return { name: item };
			}
			return item;
		});

		return [...resultArr, ''];
	}
};
