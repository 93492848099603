// Core
import React, { useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

// UI
import { FormInput } from 'components/Form/FormInput';
import AuthorMain from './AuthorMain/AuthorMain';
import PublisherMain from './PublisherMain/PublisherMain';
import LastSavePerson from './LastSavePerson/LastSavePerson';
import Controls from './Controls/Controls';
import RepertoireCmoModal from 'material-design/modals/RepertoireCmoModal/RepertoireCmoModal';
import { ArtistForm } from 'material-design/ReleaseCreate/ArtistForm';
import { SidepageModal } from 'material-design/modals';

// Icons
import close_button from 'images/close_button.svg';
import plus_in_square from 'images/plus_in_square.svg';

// Styles
import s from '../Authors.module.css';

const AuthorForm = ({
	lastSavePerson,
	errors,
	handleOnChange,
	handleOnFocus,
	handleOnBlur,
	tmpInputValue,
	handleForm,
	toogleMenu,
	selectPerson,
	setSelectPerson,
	recordingSave,
	takeByLastPerson,
	toogleLastPerson,
	modal,
	isLoading,
	onSave,
	setModal,
	disableSaveBtn,
	isH11Edit,
}) => {
	const [isModalActive, setIsModalActive] = useState(false);
	const [selectedPerformer, setSelectedPerformer] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(null);

	const modalOpen = (active) => setModal(active);

	const addAuthor = () => {
		selectPerson.contributors.push({
			title: '',
			society_id: null,
			roles: [],
			ownership: 0.1,
			publisher: '',
			publisher_share: 0,
			controlled_by_submitter: true,
			contributor_author_id: '',
		});
		setSelectPerson({ ...selectPerson });
	};

	const deleteAuthor = (index) => {
		selectPerson.contributors.splice(index, 1);
		setSelectPerson({ ...selectPerson });
	};

	const addPublisher = (index) => {
		selectPerson.contributors[index].publisher_show = true;
		const newNumber = (selectPerson.contributors[index].ownership / 2).toFixed(
			2
		);

		selectPerson.contributors[index].publisher_share = newNumber;
		selectPerson.contributors[index].ownership = newNumber;
		setSelectPerson({ ...selectPerson });
	};

	const deletePublisher = (index) => {
		selectPerson.contributors[index].publisher_show = false;
		var newNumber = (
			Number(selectPerson.contributors[index].publisher_share) +
			Number(selectPerson.contributors[index].ownership)
		).toFixed(2);
		selectPerson.contributors[index].publisher = '';
		selectPerson.contributors[index].publisher_share = 0;

		newNumber = newNumber > 100 ? 100 : newNumber;
		selectPerson.contributors[index].ownership = newNumber;
		setSelectPerson({
			...selectPerson,
		});
	};

	const ckeckErrorsBeforeSave = () => {
		if (errors.sum === '') {
			return <FormattedMessage id={`rod.release.create.sum`} />;
		}

		if (errors.roles.includes('required_roles')) {
			return <FormattedMessage id={`rod.error.required_roles`} />;
		}
	};

	const handleChangeArtist = (data, index, field) => {
		selectPerson.contributors[index].contributor_author_id = data;
		selectPerson.contributors[index].title = data.name;
		setSelectPerson({ ...selectPerson });
	};

	const handleSavePerformer = (updatedPerformer, index) => {
		selectPerson.contributors[index].contributor_author_id = updatedPerformer;
		selectPerson.contributors[index].title = updatedPerformer.name;
		setSelectPerson({ ...selectPerson });
	};

	const handleOpenModal = (performer, index, text) => {
		setSelectedPerformer(!performer.name ? { name: text } : performer);
		setSelectedIndex(index);
		setIsModalActive(true);
	};

	return (
		<>
			<div className={s.form}>
				<img
					src={close_button}
					className={s.form__close}
					onClick={() => handleForm(toogleMenu)}
					alt=""
				/>

				{Object.keys(lastSavePerson).length !== 0 && (
					<LastSavePerson
						takeByLastPerson={takeByLastPerson}
						toogleLastPerson={toogleLastPerson}
					/>
				)}

				<div className={s.authorsAndShares}>
					{selectPerson?.contributors &&
						selectPerson?.contributors.map((_, index) => (
							<div key={index} className={s.author__item}>
								<AuthorMain
									index={index}
									handleOnChange={handleOnChange}
									handleOnFocus={handleOnFocus}
									handleOnBlur={handleOnBlur}
									deleteAuthor={deleteAuthor}
									errors={errors}
									selectPerson={selectPerson}
									tmpInputValue={tmpInputValue}
									setModal={setModal}
									isLoading={isLoading}
									onSave={onSave}
									handleChangeArtist={handleChangeArtist}
									isH11Edit={isH11Edit}
									handleOpenModal={handleOpenModal}
								/>
								{selectPerson.contributors[index]?.publisher_show && (
									<PublisherMain
										handleOnChange={handleOnChange}
										handleOnFocus={handleOnFocus}
										handleOnBlur={handleOnBlur}
										index={index}
										errors={errors}
										selectPerson={selectPerson}
										tmpInputValue={tmpInputValue}
										setModal={setModal}
										deletePublisher={deletePublisher}
										isLoading={isLoading}
										onSave={onSave}
									/>
								)}
								<div className={s.addPublisher}>
									{!selectPerson.contributors[index]?.publisher_show && (
										<div
											className={s.button__addPublisher}
											onClick={() => addPublisher(index)}
										>
											<img src={plus_in_square} alt="" />
											<FormattedHTMLMessage
												id={
													'rod.release.create.step.recording_info.label.add_publisher'
												}
											/>
										</div>
									)}
									<FormInput
										checked={selectPerson.contributors[index]}
										className={s.author__checkbox}
										type={'muiCheckbox'}
										name={'controlled_by_submitter'}
										onChange={handleOnChange(index)}
										errors={errors}
										data={selectPerson.contributors[index]}
										label={
											<FormattedMessage id={'rod.authors_shares.my_shares'} />
										}
									/>
								</div>
							</div>
						))}
				</div>
				<Controls
					addAuthor={addAuthor}
					recordingSave={recordingSave}
					errors={errors}
					ckeckErrorsBeforeSave={ckeckErrorsBeforeSave}
					disableSaveBtn={disableSaveBtn}
				/>
			</div>
			{modal && (
				<RepertoireCmoModal
					type="release"
					active={modal}
					setActive={modalOpen}
					selectPersonId={selectPerson.id}
				/>
			)}
			<SidepageModal
				customWidth="434px"
				headerBottom="12px"
				active={isModalActive}
				setActive={() => setIsModalActive(false)}
			>
				<ArtistForm
					isModalActive={isModalActive}
					data={selectedPerformer}
					dataField="performers"
					index={selectedIndex}
					setIsModalActive={setIsModalActive}
					onSave={handleSavePerformer}
				/>
			</SidepageModal>
		</>
	);
};

export default AuthorForm;
