// Core
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// Utils
import { getPerformers, formatDuration } from 'utils';

// Icons
import stop_button from 'images/stop_button.svg';
import play_button from 'images/play_button.svg';
import explicit from 'images/explicit_content.svg';

// Styles
import s from './Phonograms.module.css';

const Phonograms = ({ tracks }) => {
	const { play, handlePlay, audioId, handlePlayStop } = useContext(UIContext);
	const history = useHistory();

	return (
		<div>
			<div className={s.titleWrapper}>
				<span className={s.title}>
					<FormattedMessage id={'rod.release.info.tracks'} />
				</span>
			</div>
			{tracks.length > 0 && (
				<>
					<div className={`${s.table__header} ${s.table} ${s.grid}`}>
						<FormattedHTMLMessage id={'№'} />
						<FormattedHTMLMessage id={'rod.for_all.title'} />
						<FormattedHTMLMessage id={'rod.for_all.subtitle'} />
						<FormattedHTMLMessage id={'ISRC'} />
						<FormattedHTMLMessage id={'rod.for_all.performer'} />
						<FormattedHTMLMessage id={'rod.recordery.info.length'} />
						<FormattedHTMLMessage id={'rod.for_all.explicit'} />
					</div>
					<ul className={s.table__body}>
						{tracks.map((item, index) => (
							<li
								className={`${s.table__item} ${s.table} ${s.grid}`}
								key={item.id}
								style={
									item.asset_id
										? { cursor: 'pointer' }
										: { pointerEvents: 'none' }
								}
								onClick={() =>
									history.push(`/repertoire/recordings/${item.asset_id}`)
								}
							>
								<span className={s.item__number}>{item.track_number}</span>
								<span title={item.title}>{item.title}</span>
								<span title={item.subtitle}>{item.subtitle}</span>
								<span title={item.isrc}>{item.isrc ? item.isrc : '-'}</span>
								<div className={s.item__performersWrapper}>
									<span
										title={getPerformers(item)}
										className={s.item__performers}
									>
										{getPerformers(item)}
									</span>
								</div>
								<span>
									{item.preview_length
										? formatDuration(item.preview_length)
										: '-'}
								</span>
								<span>
									{item.parental_warning_type === 'explicit' ? (
										<img
											src={explicit}
											width="24px"
											height="24px"
											alt="explicit"
										/>
									) : (
										''
									)}
								</span>
								{item.preview &&
									(audioId === item.id && play ? (
										<img
											src={stop_button}
											className={s.play}
											onClick={handlePlayStop}
											alt=""
										/>
									) : (
										<img
											src={play_button}
											className={s.play}
											onClick={() =>
												handlePlay(index, item, item.title, item.performers)
											}
											alt=""
										/>
									))}
							</li>
						))}
					</ul>
				</>
			)}
		</div>
	);
};

export default Phonograms;
